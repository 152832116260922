import React from "react";
import {
  Label,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  BarChart, 
  Bar,
  Rectangle
} from "recharts";

export default function BarCharts({ data }) {
  const isMobile = false;
  const [payload, setPayload] = React.useState([]);

  React.useEffect(() => {
    let aux = [];
    data.reverse().map((read) => {
      const date = new Date(read.createdAt)
      aux.push({
        date: new Intl.DateTimeFormat("en-GB").format(date),
        llenado: read.fill,
      });
      return null;
    });
    setPayload(aux);
  }, [data]);

  return (
    <ResponsiveContainer width="100%" aspect={isMobile ? 4.0 / 4.0 : 4.0 / 1}>
        <BarChart data={payload}>
            <XAxis dataKey="date"> <Label value="Fecha" offset={-5}  position="insideBottom" /></XAxis>
            <YAxis ><Label value="Llenado" angle="-90" position="insideLeft" /></YAxis>
            <Tooltip formatter={(Llenado) => Llenado + " %"}/>
            <Bar dataKey="llenado" fill="#8884d8" activeBar={<Rectangle fill="pink" stroke="blue" />} />
        </BarChart>
    </ResponsiveContainer>
  )
}

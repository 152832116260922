import React from "react";
import {
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  DialogContent,
  TextField,
  Dialog,
  Typography, Tooltip,
} from "@material-ui/core";
import LinearProgressFill from "../LinearProgressFill";
import LinearProgressBattery from "../LinearProgressBattery";
import SwitchButton from "./Switch";
import ClearOutlinedIcon from "@material-ui/icons/ClearOutlined";
import PropTypes from "prop-types";
import { useSensorsContext } from "../../../../context/SensorsContext";
import BarCharts from "./Barchart";
const style = {
  fullWidth: true,
  margin: "dense",
  variant: "outlined",
  InputProps: {
    readOnly: true,
  },
};

export default function ViewSensor({ setView, sensor }) {
  console.log(sensor);
  const [value, setValue] = React.useState(false);
  const [sensorState, setSensorState] = React.useState(true);
  const { helpers } = useSensorsContext();
  const [formData, setFormData] = React.useState({
    model: sensor.model,
    imei: sensor.imei,
    material: sensor.material,
    state: sensor.state,
  });
  const updateFormData = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  return (
      <Dialog
          scroll="body"
          open={true}
          onClose={() => setView("TABLE_SENSORS")}
          maxWidth="md"
          fullWidth
      >
        <DialogContent>
          <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
          >
            <Grid item>
              <Typography component="h4" variant="h5">
                Ver sensor
              </Typography>
            </Grid>
            <Grid item>
              <Button color="primary" onClick={() => setView("TABLE_SENSORS")}>
                <ClearOutlinedIcon />
              </Button>
            </Grid>
            <Grid item xs={12}>
              <hr />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                      margin="dense"
                      fullWidth
                      value={formData.imei}
                      name="imei"
                      label="IMEI"
                      variant="outlined"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      onChange={(e)=>updateFormData(e)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                      margin="dense"
                      variant="outlined"
                      name="material"
                      fullWidth
                      defaultValue={helpers[sensor.material.toLowerCase()].name}
                      label="Material"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      onChange={(e)=>updateFormData(e)}
                      >
                  </TextField>
                </Grid>
                <Grid item xs={3}>
                  <TextField
                      margin="dense"
                      variant="outlined"
                      name="state"
                      fullWidth
                      value={sensor.state === "READY" ? "Activo" : "Inactivo"}
                      label="Estado"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      type="text"
                      onChange={(e)=>updateFormData(e)}
                  />
                </Grid>
                <Grid item xs={6} align="center">
                  <div
                      style={{
                        borderRadius: 4,
                        padding: 6,
                        border: `solid 1px #e0e0e0`,
                      }}
                  >
                    <Typography variant="body2">
                      Habilitar sensor / Deshabilitar sensor
                    </Typography>
                    <SwitchButton
                        value={Boolean(sensorState)}
                        setValue={setSensorState}
                        label={sensorState ? "Habilitado" : "Deshabilitado"}
                    />
                  </div>
                </Grid>
                <Grid item xs={6} align="center">
                  <div
                      style={{
                        borderRadius: 4,
                        padding: 6,
                        border: `solid 1px #e0e0e0`,
                      }}
                  >
                    <Typography variant="body2">
                      Permitir a este sensor solicitar retiros
                    </Typography>
                    <SwitchButton
                        value={Boolean(value)}
                        setValue={setValue}
                        label={value ? "Permitido" : "No permitido"}
                    />
                  </div>
                </Grid>
                
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="h6">Últimas lecturas</Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Fecha</TableCell>
                    <TableCell>Batería</TableCell>
                    <TableCell>Llenado</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sensor.sensorStates.map((read, i) => (
                      <TableRow key={i}>
                        <TableCell>
                          {read.updatedAt ? (
                              <div>
                                {new Intl.DateTimeFormat("es-CL", {
                                  year: "numeric",
                                  month: "long",
                                  day: "2-digit",
                                }).format(new Date(read.updatedAt))}
                              </div>

                          ) : (
                              <em>No se registra</em>
                          )}
                        </TableCell>
                        <TableCell>
                          <LinearProgressBattery value={read.battery} />
                        </TableCell>
                        <TableCell>
                          <LinearProgressFill value={read.fill} />
                        </TableCell>
                      </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>

            <Grid item xs={12}>
              <BarCharts data={sensor.sensorStates} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  );
}

ViewSensor.propTypes = {
  setView: PropTypes.func.isRequired,
  sensor: PropTypes.object.isRequired,
};
